<template>
  <div>
    <v-row class="mb-n3">
      <v-col cols="6" md="3">
        <SalutationSelect
          v-model="user.salutation"
          label="USER_PROFILE.UPDATE.FORM.LABELS.SALUTATION"
          required
          :error="!!hasErrors('salutation')"
          :error-messages="getErrors('salutation')"
        />
      </v-col>
    </v-row>

    <v-row class="mb-n3">
      <v-col cols="12" md="6">
        <BaseTextField
          v-model="user.firstName"
          label="USER_PROFILE.UPDATE.FORM.LABELS.FIRST_NAME"
          required
          :error="!!hasErrors('firstName')"
          :error-messages="getErrors('firstName')"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.LAST_NAME"
          v-model="user.lastName"
          required
          :error="!!hasErrors('lastName')"
          :error-messages="getErrors('lastName')"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.EMAIL"
          v-model="user.emailMain"
          required
          :error="!!hasErrors('emailMain')"
          :error-messages="getErrors('emailMain')"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.STREET"
          v-model="user.street"
          required
          :error="!!hasErrors('street')"
          :error-messages="getErrors('street')"
        />
      </v-col>

      <v-col cols="12" md="4" lg="3">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.ZIP_CODE"
          v-model="user.zipCode"
          required
          :error="!!hasErrors('zipCode')"
          :error-messages="getErrors('zipCode')"
          :rules="zipRules"
        />
      </v-col>

      <v-col cols="12" md="8" lg="9">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.CITY"
          v-model="user.city"
          required
          :error="!!hasErrors('city')"
          :error-messages="getErrors('city')"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.PHONE"
          v-model="user.phone"
          required
          :error="!!hasErrors('phone')"
          :error-messages="getErrors('phone')"
        />
      </v-col>

      <v-col cols="12" md="6">
        <BaseTextField
          label="USER_PROFILE.UPDATE.FORM.LABELS.MOBILE_PHONE"
          v-model="user.mobile"
          required
          :error="!!hasErrors('mobile')"
          :error-messages="getErrors('mobile')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <BaseText body="2">
          {{ `* ${$t("USER_PROFILE.UPDATE.FORM.REQUIRED")}` }}
        </BaseText>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="auto">
        <BaseButton @click="$emit('submit')" :loading="loading" :disabled="disableButton">
          {{ $t("USER_PROFILE.UPDATE.FORM.BUTTON_LABEL") }}
        </BaseButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useEnterKey from "@/use/useEnterKey";
import SalutationSelect from "@components/FormElements/SalutationSelect.vue";
import formValidation from "@mixins/formValidation";

export default {
  name: "EditForm",

  mixins: [formValidation],

  components: {
    SalutationSelect,
  },

  setup(props, { emit }) {
    useEnterKey(() => emit("submit"));
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    isParticipant: {
      type: Boolean,
      required: false,
      default: false,
    },

    isSpeaker: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableButton: {
      type: Boolean,
      required: false,
      default: true,
    },

    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  computed: {
    user: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    errors() {
      this.validationErrors = this.errors;
    },
  },
};
</script>
