/**
 * Will reset all values in object
 *
 * @param {object} objectToReset
 * @returns {object}
 * @author Dominik Niemann <dominik@polargold.de>
 */
const resetObjectHelper = (objectToReset) => {
  const resetData = { ...objectToReset };

  Object.keys(resetData).forEach((index) => {
    // eslint-disable-next-line no-param-reassign
    resetData[index] = null;
  });

  return resetData;
};

export default resetObjectHelper;
