import nullifyHelper from "@/helper/nullifyHelper";
import resetObjectHelper from "@/helper/resetObjectHelper";

export default class UserProfile {
  constructor(userProfile) {
    this.userProfile = { ...userProfile };
  }

  get send() {
    const sendReadyProfile = {
      salutation: this.userProfile.salutation,
      firstName: this.userProfile.firstName,
      lastName: this.userProfile.lastName,
      email: this.userProfile.email,
      street: this.userProfile.street,
      zipCode: this.userProfile.zipCode,
      city: this.userProfile.city,
      emailMain: this.userProfile.emailMain,
      emailSub: null,
      phone: this.userProfile.phone,
      mobile: this.userProfile.mobile,
    };

    return nullifyHelper(sendReadyProfile);
  }

  get reset() {
    return resetObjectHelper(this.userProfile);
  }
}
