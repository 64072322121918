import regex from "@/data/regex";

const emptyString = "";

/**
 * Check if item is an empty string
 *
 * @ignore
 * @param {*} value
 * @returns {boolean}
 */
const hasWhiteSpace = (value) => {
  if (typeof value === "object") return false;

  return regex.whiteSpace.test(value);
};

/**
 * Check if item only consists of white space
 *
 * @ignore
 * @param {*} value
 * @returns {boolean}
 */
const hasOnlyWhiteSpace = (value) => {
  if (hasWhiteSpace(value)) {
    return value.replace(regex.whiteSpace, emptyString) === emptyString;
  }

  return false;
};

/**
 * Loop through an object and convert empty strings to null
 *
 * @param {object} data
 * @returns {object}
 * @author Dominik Niemann <dominik@polargold.de>
 */
const nullifyHelper = (data) => {
  const nullified = { ...data };

  Object.keys(nullified).forEach((key) => {
    if (
      nullified[key] === emptyString ||
      nullified[key] === undefined ||
      hasOnlyWhiteSpace(nullified[key])
    ) {
      // eslint-disable-next-line no-param-reassign
      nullified[key] = null;
    }
  });

  return nullified;
};

export default nullifyHelper;
