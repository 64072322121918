<template>
  <ModuleSubPage title="USER_PROFILE.UPDATE.TITLE" :loading="isLoading">
    <BaseAlert v-if="!hasUserProfile && !isLoading">
      {{ $t("USER_PROFILE.SHOW.NO_PROFILE") }}
    </BaseAlert>

    <div v-if="hasUserProfile && !isLoading">
      <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
        <EditForm
          v-model="user"
          :loading="isLoading"
          @submit="submit"
          :disableButton="!hasChanges"
          :errors="validationErrors"
        />
      </v-form>
    </div>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import EditForm from "./partials/EditForm.vue";
import formValidation from "@mixins/formValidation";
import UserProfile from "@/classes/authUser/UserProfile";
import { isEqual } from "lodash";
import { mapActions } from "vuex";
import BaseAlert from "@components/Base/BaseAlert.vue";

export default {
  name: "UserProfileUpdate",

  mixins: [formValidation],

  components: {
    BaseAlert,
    ModuleSubPage,
    EditForm,
  },

  data() {
    return {
      user: {
        salutation: null,
        firstName: null,
        lastName: null,
        email: null,
        title: null,
      },

      userInit: null,
      isLoading: false,
      hasUserProfile: false,
    };
  },

  computed: {
    hasChanges() {
      return !isEqual(this.userInit, this.user);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    goToEditPage() {
      this.$router.push({ name: "UserProfileUpdate" });
    },

    async showUserProfile() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.authUser.showPerson();
        this.user = data.data;
        this.hasUserProfile = true;
        this.userInit = { ...this.user };
      } finally {
        this.isLoading = false;
      }
    },

    async updateAuthUserProfile() {
      const payload = new UserProfile(this.user);
      await this.$axios.authUser.createPersonChange(payload.send);

      return Promise.resolve();
    },

    async goToProfilePage() {
      try {
        await this.$router.push({ name: "UserProfile" });
      } catch {
        return;
      }
    },

    submit() {
      this.validate();

      this.$nextTick(async () => {
        this.isLoading = true;
        if (this.formValidation) {
          try {
            await this.updateAuthUserProfile();
            await this.success();
            await this.goToProfilePage();
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    async success() {
      await this.notify({
        type: "success",
        message: "USER_PROFILE.UPDATE.SUBMIT_SUCCESS",
      });
    },
  },

  async created() {
    await this.showUserProfile();
  },
};
</script>
